import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet';

function Business(props) {
  const business = [
    {
      category: 'Ecommerce',
      description: 'Ecom discription',
      image: 'Assets/Images/ecommerce.jpg',
      to: '/ecommerce',
    },
    {
      category: 'Prime Plastics',
      description: 'Prime Plastics discription',
      image: 'Assets/Images/abs-banner.jpg',
      to: '/prime_plastics',
    },
    {
      category: 'Petrochemicals',
      description: 'Petrochemicals discription',
      image: 'Assets/Images/oil_refinery.jpg',
      to: '/petrochemicals',
    },
    {
      category: 'Metal',
      description: 'Metal discription',
      image: 'Assets/Images/melting_metal.jpg',
      to: '/metal',
    },
    {
      category: 'Foods',
      description: 'Foods discription',
      image: 'Assets/Images/foods.jpg',
      to: '/foods',
    },
    {
      category: 'Auto Industry',
      description: 'Auto industry discription',
      image: 'Assets/Images/auto_industry.jpg',
      to: '/auto_industry',
    },
    {
      category: 'Property',
      description: 'Property discription',
      image: 'Assets/Images/property.jpg',
      to: '/property',
    },
    {
      category: 'Mining',
      description: 'Mining discription',
      image: 'Assets/Images/property.jpg',
      to: '/mining',
    },
    {
      category: 'Precious Gems Stones',
      description: 'Precious Gems discription',
      image: 'Assets/Images/property.jpg',
      to: '/gems_stones',
    },
  ];

  const ecommerce = [
    {
      category: 'Clothings',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/cloths.jpg',
      // to: '/ecommerce',
    },
    {
      category: 'Footware',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/footware.jpg',
      // to: '/prime_plastics',
    },
    {
      category: 'Health and Beauty',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/health_beauty.jpg',
      // to: '/petrochemicals',
    },
    {
      category: 'Handmade Products',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/handmade.jpg',
      // to: '/metal',
    },
    {
      category: 'Home accessories',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/accessories.jpg',
      // to: '/foods',
    },
    {
      category: 'Gardening',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/gardening.jpg',
      // to: '/auto_industry',
    },
    {
      category: 'Electronic',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/tech_it.jpg',
      // to: '/peoperty',
    },
    {
      category: 'Decoration',
      description: 'We provide services in branding, designing and Production',
      image: 'Assets/Images/decoration.jpg',
      // to: '/peoperty',
    },
  ];

  const prime_plastics = [
    {
      category: 'PET',
      description:
        'We can supply prime and recycled PET from UK and EU market direct to buyers',
      image: 'Assets/Images/Prime_Plastics/PET.jpg',
      // to: '/ecommerce',
    },
    {
      category: 'PVC',
      description:
        'We can supply PVC resin K67, SG57, K65 from World recoganize refineries and distributer.',
      image: 'Assets/Images/Prime_Plastics/PVC.jpg',

      // to: '/prime_plastics',
    },
    {
      category: 'Polyethylene',
      description:
        'We can supply Prime and Recycled HDPE, LDPE and LLDPE material',
      image: 'Assets/Images/Prime_Plastics/polyethylene.jpg',

      // to: '/petrochemicals',
    },
    {
      category: 'Polystyrene',
      description: 'We can supply Prime GPPS,HIPS,EPS and XPS material',
      image: 'Assets/Images/Prime_Plastics/polystyrene.jpg',

      // to: '/metal',
    },
    {
      category: 'Polypropylene',
      description: 'W can supply all PP prime and recycled material',
      image: 'Assets/Images/Prime_Plastics/polypropylene.jpg',

      // to: '/foods',
    },
  ];
  const petrochemicals = [
    {
      category: 'Petroleum',
      description: 'Please contact us for weekly stock list',
      image: 'Assets/Images/Petrochemicals/petroleum.jpg',
      // to: '/ecommerce',
    },
    {
      category: 'Fuels',
      description: 'Please contact us for weekly stock list',
      image: 'Assets/Images/Petrochemicals/fuels.jpg',

      // to: '/prime_plastics',
    },
    // {
    //   category: 'Polyethylene',
    //   description: 'Polyethylene discription',
    //   image: 'Assets/Images/Prime_Plastics/polyethylene.jpg',

    //   // to: '/petrochemicals',
    // },
    // {
    //   category: 'Polystyrene',
    //   description: 'Polystyrene discription',
    //   image: 'Assets/Images/Prime_Plastics/polystyrene.jpg',

    //   // to: '/metal',
    // },
    // {
    //   category: 'Polypropylene',
    //   description: 'Polypropylene discription',
    //   image: 'Assets/Images/Prime_Plastics/polypropylene.jpg',

    //   // to: '/foods',
    // },
  ];

  const metal = [
    {
      category: 'Ferrous',
      description: 'Ferrous discription',
      image: 'Assets/Images/Metals/ferrous.jpg',
      // to: '/ecommerce',
    },
    {
      category: 'Non-Ferrous',
      description: 'Non-Ferrous discription',
      image: 'Assets/Images/Metals/non_ferrous.png',

      // to: '/prime_plastics',
    },
    {
      category: 'Scrap',
      description: 'Scrap discription',
      image: 'Assets/Images/Metals/scrap.jpg',

      // to: '/petrochemicals',
    },
    // {
    //   category: 'Polystyrene',
    //   description: 'Polystyrene discription',
    //   image: 'Assets/Images/Prime_Plastics/polystyrene.jpg',

    //   // to: '/metal',
    // },
    // {
    //   category: 'Polypropylene',
    //   description: 'Polypropylene discription',
    //   image: 'Assets/Images/Prime_Plastics/polypropylene.jpg',

    //   // to: '/foods',
    // },
  ];

  const trading = [
    {
      category: 'Sugar',
      description: 'Sugar discription',
      image: 'Assets/Images/General Trading/sugar.jpg',
      // to: '/ecommerce',
    },
    {
      category: 'Spices',
      description: 'Spices discription',
      image: 'Assets/Images/General Trading/spices.jpg',

      // to: '/prime_plastics',
    },
    {
      category: 'Cooking Oil',
      description: 'Cooking oil discription',
      image: 'Assets/Images/General Trading/oil.jpg',

      // to: '/petrochemicals',
    },
    {
      category: 'Wheat',
      description: 'wheat discription',
      image: 'Assets/Images/General Trading/wheat.jpg',

      // to: '/metal',
    },
    // {
    //   category: 'Polypropylene',
    //   description: 'Polypropylene discription',
    //   image: 'Assets/Images/Prime_Plastics/polypropylene.jpg',

    //   // to: '/foods',
    // },
  ];

  const pgm_mining = [
    {
      title: 'Platinum',
      description: 'Ore / pure',
      image: 'Assets/Images/PGM Metals/platinum.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Iridium',
      description: 'Ore / pure',
      image: 'Assets/Images/PGM Metals/iridium.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Osmium',
      description: 'Ore / pure',
      image: 'Assets/Images/PGM Metals/Osmium.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Palladium',
      description: 'Ore / pure',
      image: 'Assets/Images/PGM Metals/palladium.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Rhodium',
      description: 'Ore / pure',
      image: 'Assets/Images/PGM Metals/rhodium.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Ruthenium',
      description: 'Ore / pure',
      image: 'Assets/Images/PGM Metals/ruthenium.jpg',
      // to: '/ecommerce',
    },
  ];

  const gems = [
    {
      title: 'Diamonds',
      description: 'Diamond discription',
      image: 'Assets/Images/Gems Stones/diamond.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Ruby',
      description: 'Ruby discription',
      image: 'Assets/Images/Gems Stones/rubbies.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Emerald',
      description: 'Emerald discription',
      image: 'Assets/Images/Gems Stones/embreld.jpg',
      // to: '/ecommerce',
    },
    {
      title: 'Sapphire',
      description: 'Sapphire discription',
      image: 'Assets/Images/Gems Stones/sapphier.jpg',
      // to: '/ecommerce',
    },
  ];

  const location = useLocation();
  const [data, setdata] = useState(location.state?.category);
  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <Box
      className=' w-full'
      style={{
        backgroundImage: "url('Assets/Images/boxes.jpg')",
        // color: 'white',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Helmet>
        <meta
          charSet='utf-8'
          name='Businesses'
          content='At Dawn Consultancy Ltd, 
         We are dedicated to tailoring each business
          engagement according to the unique methodologies embraced by
           its proprietors. Primarily, our focus is on fostering trade 
           stabilization among key regions, including the UK, Europe, USA, Scandinavia,
            and Eastern European countries, with the ultimate goal of unifying Asian, Arab,
          and African markets under a single, accessible platform.'
        />
        <title>Businesses - Dawn Consultancy LTD</title>
      </Helmet>
      <NavBar />

      <Box
        className='w-full h-96 flex justify-center shadow-lg rounded overflow-hidden'
        style={
          {
            // backgroundImage: "url('Assets/Images/about_oil_refinery.jpg')",
            // color: 'white',
            // backgroundAttachment: 'fixed',
            // background: 'black'
          }
        }
      >
        <Box
          className='flex justify-center rounded pt-20 w-full z-0'
          // style={{ background: 'black' }}
        >
          <Box className=''>
            <Typography
              className='pt-20 box'
              variant='h3'
              style={{
                fontFamily: 'Poppins',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Businesses
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        className='border shadow -mt-12 z-40 overflow-hidden '
        style={{
          position: 'relative',
          zIndex: 10,
          padding: '10px',
          marginLeft: 10,
          marginRight: 10,
          width: '98%',
          // height: '200px',
          // zIndex: '2',
          background: 'white',
          borderRadius: 20,
        }}
      >
        {/* Ecommerce store */}
        <Typography
          variant='h4'
          className='pl-8 pt-4 pb-2'
          style={{
            fontFamily: 'Poppins',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
            color: '#139c76',
          }}
        >
          Ecommerce Store
        </Typography>
        <p
          className='text-2xl text-justify pl-8 pr-8'
          style={{
            fontFamily: 'Poppins',

            // textAlign: 'center',
          }}
        >
          Revolutionize Your E-Commerce Odyssey: Strategic Guidance for Digital
          Dominance. we sculpt success in pixels, not just pages. Unleash your
          brand's online potential with our expert consultants – where
          innovation meets e-commerce brilliance.
        </p>
        <Box>
          <Typography
            className='pl-8 pt-4'
            variant='h5'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#DAA520',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid justify-center sm:justify-center grid-cols-1 sm:grid-cols-2 justify-center lg:grid-cols-4 md:grid-cols-3 p-4 gap-10 '
        >
          {ecommerce.map((value, index) => {
            return (
              <div className='cat_card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full '
                />
                <div className='cat_card-body text-center w-full h-full'>
                  <h2>{value.category}</h2>
                  <p className='text-justify'>{value.description}</p>
                </div>
              </div>
            );
          })}
        </Box>

        <Divider />

        {/* Plastics */}

        <Typography
          variant='h4'
          className='pl-8 pt-8 pb-2'
          style={{
            fontFamily: 'Poppins',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
            color: '#139c76',
          }}
        >
          Plastics
        </Typography>
        <Typography
          variant='h5'
          className='pl-8 pb-2'
          style={{
            fontFamily: 'Poppins',
            color: '#FFA63D',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
          }}
        >
          Prime Plastic and Recycled Plastic
        </Typography>
        <p
          className='text-justify text-2xl pl-8 pr-8'
          style={{
            fontFamily: 'Poppins',

            // textAlign: 'center',
          }}
        >
          Sculpting Success in Polymer Precision: Prime and Recycled Plastics
          Consultancy. Elevate your business with us, where we craft pathways to
          prosperity in the dynamic world of polymer innovation
        </p>
        <Box>
          <Typography
            className='pl-8 pt-4'
            variant='h5'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#DAA520',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid justify-center sm:justify-center grid-cols-1 sm:grid-cols-2 justify-center lg:grid-cols-4 md:grid-cols-3 p-4 gap-10 '
        >
          {prime_plastics.map((value, index) => {
            return (
              <div className='cat_card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full '
                />
                <div className='cat_card-body text-center w-full h-full'>
                  <h2>{value.category}</h2>
                  <p className='text-justify'>{value.description}</p>
                </div>
              </div>
            );
          })}
        </Box>

        <Divider />

        {/* Petro chemicals */}

        <Typography
          variant='h4'
          className='pl-8 pt-8 pb-2'
          style={{
            fontFamily: 'Poppins',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
            color: '#139c76',
          }}
        >
          Petrochemicals
        </Typography>
        <p
          className='text-justify text-2xl pl-8 pr-8'
          style={{
            fontFamily: 'Poppins',

            // textAlign: 'center',
          }}
        >
          Elevate Your Business with Precision Consultancy. From refining
          strategies to optimizing growth, we're your compass in the dynamic
          world of petrochemicals – where every solution sparks a new wave of
          success.
        </p>
        <Box>
          <Typography
            className='pl-8 pt-4'
            variant='h5'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#DAA520',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid justify-center sm:justify-center grid-cols-1 sm:grid-cols-2 justify-center lg:grid-cols-4 md:grid-cols-3 p-4 gap-10 '
        >
          {petrochemicals.map((value, index) => {
            return (
              <div className='cat_card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full '
                />
                <div className='cat_card-body text-center w-full h-full'>
                  <h2>{value.category}</h2>
                  <p className='text-justify'>{value.description}</p>
                </div>
              </div>
            );
          })}
        </Box>

        <Divider />

        {/* Gems Stones */}

        <Typography
          variant='h4'
          className='pl-8 pt-8 pb-2'
          style={{
            fontFamily: 'Poppins',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
            color: '#139c76',
          }}
        >
          Precious Gems Stones
        </Typography>

        <Box>
          <Typography
            className='pl-8 pt-4'
            variant='h5'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#DAA520',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid justify-center sm:justify-center grid-cols-1 sm:grid-cols-2 justify-center lg:grid-cols-4 md:grid-cols-3 p-4 gap-10 '
        >
          {gems.map((value, index) => {
            return (
              <div className='subcat_card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full '
                />
                <div className='subcat_card-body text-center w-full h-full'>
                  <h2>{value.title}</h2>
                  {/* <p>{value.description}</p> */}
                </div>
              </div>
            );
          })}
        </Box>

        <Divider />

        {/* PGM Metals */}

        <Typography
          variant='h4'
          className='pl-8 pt-8 pb-2'
          style={{
            fontFamily: 'Poppins',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
            color: '#139c76',
          }}
        >
          Platinum Group Metals (PGMs) Mining
        </Typography>

        <Box>
          <Typography
            className='pl-8 pt-4'
            variant='h5'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#DAA520',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid justify-center sm:justify-center grid-cols-1 sm:grid-cols-2 justify-center lg:grid-cols-4 md:grid-cols-3 p-4 gap-10 '
        >
          {pgm_mining.map((value, index) => {
            return (
              <div className='cat_card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full '
                />
                <div className='cat_card-body text-center w-full h-full'>
                  <h2>{value.title}</h2>
                  <p>{value.description}</p>
                </div>
              </div>
            );
          })}
        </Box>

        <Divider />

        {/* Metal */}

        <Typography
          variant='h4'
          className='pl-8 pt-8 pb-2'
          style={{
            fontFamily: 'Poppins',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
            color: '#139c76',
          }}
        >
          Metals
        </Typography>
        <p
          className='text-justify text-2xl pl-8 pr-8'
          style={{
            fontFamily: 'Poppins',

            // textAlign: 'center',
          }}
        >
          Metallic Brilliance, Strategic Guidance:Your Partner in Thriving Metal
          Ventures. Uncover pathways to success with our tailored solutions for
          ferrous, non-ferrous, and scrap metals. Simplifying complexity, we
          pave the way for your business to shine in the world of metals
        </p>
        <Box>
          <Typography
            className='pl-8 pt-4'
            variant='h5'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#DAA520',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid justify-center sm:justify-center grid-cols-1 sm:grid-cols-2 justify-center lg:grid-cols-4 md:grid-cols-3 p-4 gap-10 '
        >
          {metal.map((value, index) => {
            return (
              <div className='subcat_card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full '
                />
                <div className='sub cat_card-body text-center w-full h-full'>
                  <h2>{value.category}</h2>
                  {/* <p>{value.description}</p> */}
                </div>
              </div>
            );
          })}
        </Box>

        <Divider />

        {/* Trading */}

        <Typography
          variant='h4'
          className='pl-8 pt-8 pb-2'
          style={{
            fontFamily: 'Poppins',

            fontWeight: 'bold',
            // textAlign: 'center',
            // color: 'rgb(25 118 210)',
            color: '#139c76',
          }}
        >
          General Trading
        </Typography>
        <p
          className='text-justify text-2xl pl-8 pr-8'
          style={{
            fontFamily: 'Poppins',

            // textAlign: 'center',
          }}
        >
          Trade Brilliance, Tailored Solutions:Your Partner in General Trading
          Success. From sugar to spices, we simplify global commerce, providing
          strategic guidance for your business to thrive in the dynamic market
          landscape
        </p>
        <Box>
          <Typography
            className='pl-8 pt-4'
            variant='h5'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#DAA520',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid justify-center sm:justify-center grid-cols-1 sm:grid-cols-2 justify-center lg:grid-cols-4 md:grid-cols-3 p-4 gap-10 '
        >
          {trading.map((value, index) => {
            return (
              <div className='subcat_card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full '
                />
                <div className='subcat_card-body text-center w-full h-full'>
                  <h2>{value.category}</h2>
                  {/* <p>{value.description}</p> */}
                </div>
              </div>
            );
          })}
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}

export default Business;
