import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../../NavBar/NavBar';
import Footer from '../../Footer/Footer';


function Ecommerce() {
  const ecommerce = [
    {
      category: 'Cloths',
      description: 'Cloths discription',
      image: 'Assets/Images/cloths.jpg',
      // to: '/ecommerce',
    },
    {
      category: 'Footware',
      description: 'Footware discription',
      image: 'Assets/Images/footware.jpg',
      // to: '/prime_plastics',
    },
    {
      category: 'Health and Beauty',
      description: 'Health and beauty discription',
      image: 'Assets/Images/health_beauty.jpg',
      // to: '/petrochemicals',
    },
    {
      category: 'Handmade',
      description: 'handmade products discription',
      image: 'Assets/Images/handmade.jpg',
      // to: '/metal',
    },
    {
      category: 'Home accessories',
      description: 'Home accessories discription',
      image: 'Assets/Images/accessories.jpg',
      // to: '/foods',
    },
    {
      category: 'Gardening',
      description: 'Gardening discription',
      image: 'Assets/Images/gardening.jpg',
      // to: '/auto_industry',
    },
    {
      category: 'Electronic',
      description: 'Electronic discription',
      image: 'Assets/Images/tech_it.jpg',
      // to: '/peoperty',
    },
    {
      category: 'Decoration',
      description: 'Decoration discription',
      image: 'Assets/Images/decoration.jpg',
      // to: '/peoperty',
    },
  ];
  const location =useLocation();
  const [data,setdata] = useState(location.state?.category);
  useEffect(() => {
    console.log(data);
  },[]);
  return (
     <Box
      className=' w-full'
      style={{
        backgroundImage: "url('Assets/Images/ecom.png')",
        // color: 'white',
        backgroundAttachment: 'fixed',
         backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
      }}
    >
      <NavBar />

      <Box
        className='w-full h-96 flex justify-center shadow-lg rounded'
        style={
          {
            // backgroundImage: "url('Assets/Images/about_oil_refinery.jpg')",
            // color: 'white',
            // backgroundAttachment: 'fixed',
            // background: 'black'
          }
        }
      >
        <Box
          className='flex justify-center rounded pt-20 w-full z-0'
          // style={{ background: 'black' }}
        >
          <Box className=''>
            <Typography
              className='pt-20 box'
              variant='h3'
              style={{
                fontFamily: 'Poppins',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Ecommerce
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        className='border shadow -mt-12 z-40 overflow-hidden '
        style={{
          position: 'relative',
          zIndex: 10,
          padding: '10px',
          marginLeft: 10,
          marginRight: 10,
          width: '98%',
          // height: '200px',
          // zIndex: '2',
          background: 'white',
          borderRadius: 20,
        }}
      >
      <Box className=''>
            <Typography
              className='p-8'
              variant='h4'
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              Featured Categories
            </Typography>
          </Box>
        <Box
          data-aos='fade-down'
          className='flex grid sm:justify-center justify-center lg:grid-cols-3 md:grid-cols-2 p-4 gap-10 '
        >
          {ecommerce.map((value, index) => {
            return (
              <div className='card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full rounded-full'
                />
                <div className='card-body text-center w-full h-full'>
                  <h2>{value.category}</h2>
                  <p>{value.description}</p>
                </div>
              </div>
            );
          })}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Ecommerce;
