import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Navdropdown from './Navdropdown';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { Facebook } from '@mui/icons-material';
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function MenuBar() {
  const [home_check, sethome_check] = useState(false);
  const [business_check, setbusiness_check] = useState(false);
  const [about_check, setabout_check] = useState(false);
  const [contact_check, setcontact_check] = useState(false);

  // const showNavbar = () => {
  //   navRef.current.classList.toggle('responsive_nav');
  // };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box style={{ color: 'black', background: 'white' }}>
      <Navbar
        expand='lg'
        // sticky='top'
        className='bg-inherit'
        style={
          {
            // background: 'rgb(25 118 210)',
          }
        }
      >
        <Container
          style={{
            // color: 'white',
            fontFamily: 'poppins',
            // fontSize: '1.2rem',
            // padding: 15,
          }}
          className='text-lg uppercase'
        >
          <Navbar.Brand as={Link} to={'/'} style={{ color: 'black' }}>
             <div className='' >
              <img src={'Assets/DAWN.png'} style={{ width: 130, height: 70 }} />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            style={{ color: 'black', background: 'white' }}
          />
          <Navbar.Collapse
            id='basic-navbar-nav'
            style={{
              // fontSize: '1.0rem',
            }}
            className='text-base'
          >
            <Nav
              className='ms-auto gap-5 text-xl'
              style={{ marginTop: width < 992 ? 20 : 0 }}
            >
              <Nav.Link
                as={Link}
                to={'/'}
                // className='text-white'
                onMouseEnter={() => sethome_check(true)}
                onMouseLeave={() => sethome_check(false)}
                style={{
                  // color: 'white',
                  color: home_check
                    // ? 'rgb(96 165 250)'
                    ? '#DAA520'
                    : '#139c76',
                  borderBottom:
                    width < 992 ? '1px solid white' : '0px solid white',
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={'/business'}
                onMouseEnter={() => setbusiness_check(true)}
                onMouseLeave={() => setbusiness_check(false)}
                style={{
                  color: business_check
                    // ? 'rgb(96 165 250)'
                    ? '#DAA520'
                    : '#139c76',
                  borderBottom:
                    width < 992 ? '1px solid white' : '0px solid white',
                }}
              >
                Businesses
              </Nav.Link>

              {/* <Navdropdown  /> */}

              {/* <NavDropdown
                style={{ color: 'white' }}
                // show={expand}

                // onMouseEnter={showDropdown}
                // onMouseLeave={hideDropdown}
                title={
                   <div>
      <Facebook name="th-list" size="lg" />
      Toto
    </div>
                }
                // id='basic-nav-dropdown'
              >
                <NavDropdown.Item href='#action/3.1' style={{ width: '5vw' }}>
                  Action
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.2'>
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href='#action/3.3'>
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='#action/3.4'>
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link
                as={Link}
                to={'/about'}
                 onMouseEnter={() => setabout_check(true)}
                onMouseLeave={() => setabout_check(false)}
                style={{
                  color: about_check
                    // ? 'rgb(96 165 250)'
                    ? '#DAA520'
                    : '#139c76',
                  borderBottom:
                    width < 992 ? '1px solid white' : '0px solid white',

                }}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={'/contact'}
                 onMouseEnter={() => setcontact_check(true)}
                onMouseLeave={() => setcontact_check(false)}
                style={{
                  color: contact_check
                    // ? 'rgb(96 165 250)'
                    ? '#DAA520'

                    : '#139c76',
                  borderBottom:
                    width < 992 ? '1px solid white' : '0px solid white',

                }}
              >
                Contact
              </Nav.Link>
              <Nav.Link as={Link}
                to={'/contact'} className='transition ease-in-out delay-15'>
                <span
                  style={{
                    fontFamily: 'poopins',
                    borderRadius: 20,
                    fontWeight: 'bold',
                    padding: 10,
                  }}
                  variant='contained'
                  className='btn_login_signup'
                >
                  Chat With Us
                </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Box>
  );
}

export default MenuBar;

{
  /* <Box
        className='shadow navbar navbar-expand-lg flex flex-wrap items-center justify-content-between sm:p-3'
        style={{
          color: 'white',
          background: 'rgb(25 118 210)',
          fontFamily: 'poppins',
          transper:0.5,
        }}
      >
        <Typography
          variant='h5'
          noWrap
          component='div'
          className='pl-4'
          style={{ fontFamily: 'poppins' }}
        >
          <Link
            to='/'
            className='nav-link text-white'
            onClick={() => setSelected_menu('Home')}
          >
            Dawn Consultancy
          </Link>
        </Typography>
        <Box className='flex'>
          <Box className='flex navbarNav' ref={navRef}>
            <ul className='navbar-nav text-white '>
              <li className='nav-item active ml-4'>
                <Link
                  to='/'
                  exact
                  className='nav-link text-white'
                  onClick={() => setSelected_menu('Home')}
                  href='#'
                  style={{
                    borderBottom:
                      selected_menu === 'Home'
                        ? '1px solid white'
                        : '0px solid white',
                    fontWeight: selected_menu === 'Home' ? 'bold' : 'normal',
                  }}
                >
                  Home <span className='sr-only'>(current)</span>
                </Link>
              </li>
              <li className='nav-item ml-4 '>
                {/* <div className='dropdown'> 
                  <Link
                    to='/business'
                    exact
                    className='flex items-center justify-between dropbtn'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className='nav-link text-white'
                    onClick={() => setSelected_menu('Business')}
                    style={{
                      fontFamily: 'poppins',
                      borderBottom:
                        selected_menu === 'Business'
                          ? '1px solid white'
                          : '0px solid white',
                      fontWeight:
                        selected_menu === 'Business' ? 'bold' : 'normal',
                    }}
                  >
                    Businesses
                    {/* {isDropdownVisible ? (
                    <RemoveOutlinedIcon
                      className='ml-4'
                      style={{ fontSize: '1.2rem' }}
                    />
                  ):(
                    <AddOutlinedIcon
                      className='ml-4'
                      style={{ fontSize: '1.2rem' }}
                    />
                  ) } 
                  </Link>
                  {/* <div className='dropdown-content'>
                  <a href='#'>Link 1</a>
                  <a href='#'>Link 2</a>
                  <a href='#'>Link 3</a>
                </div> */
}
{
  /* </div> 
              </li>
              {/* <li className='nav-item ml-4 '>
                	<input className="dropdown-sub" type="checkbox" id="dropdown-sub" name="dropdown-sub"/>
		  	<label className="for-dropdown-sub" for="dropdown-sub">Dropdown Sub <i className="uil uil-plus"></i></label>
	  		<div className="section-dropdown-sub"> 
	  			<a href="#">Dropdown Link <i className="uil uil-arrow-right"></i></a>
	  			<a href="#">Dropdown Link <i className="uil uil-arrow-right"></i></a>
	  		</div>
            </li> 
              <li className='nav-item ml-4 '>
                <Link
                  to='/contact'
                  exact
                  className='nav-link text-white '
                  onClick={() => setSelected_menu('Contact')}
                  href='#'
                  style={{
                    borderBottom:
                      selected_menu === 'Contact'
                        ? '1px solid white'
                        : '0px solid white',
                    fontWeight: selected_menu === 'Contact' ? 'bold' : 'normal',
                  }}
                >
                  Contact
                </Link>
              </li>
              <li className='nav-item ml-4 mb-2'>
                <Link
                  to='/about'
                  exact
                  className='nav-link text-white'
                  onClick={() => setSelected_menu('About')}
                  href='#'
                  style={{
                    borderBottom:
                      selected_menu === 'About'
                        ? '1px solid white'
                        : '0px solid white',
                    fontWeight: selected_menu === 'About' ? 'bold' : 'normal',
                  }}
                >
                  About
                </Link>
              </li>

              <li className='nav-item ml-16 mt-1'>
                <Button
                  style={{
                    fontFamily: 'poopins',
                    borderRadius: 20,
                    fontWeight: 'bold',
                  }}
                  className='btn_login_signup'
                  variant='contained'
                >
                  LOGIN / SIGNUP
                </Button>
              </li>
            </ul>
            <button className='nav-btn nav-close-btn' onClick={showNavbar}>
            <CloseIcon />
          </button>
          </Box>
          
          <button className='nav-btn' onClick={showNavbar}>
            <MenuIcon />
          </button>
        </Box>
      </Box> */
}
{
  /* <Search /> */
}
{
  /* <AccountCircleIcon
                onClick={() => console.log('clicked')}
                style={{
                  marginLeft: 20,
                  cursor: 'pointer',
                  width: '40px',
                  height: '40px',
                }}
              />  */
}
