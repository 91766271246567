import { Box, Typography } from '@mui/material';
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet';

AOS.init();

function About() {
  return (
    <Box
      className=' w-full'
      style={{
        backgroundImage: "url('Assets/Images/about_oil_refinery.jpg')",
        // color: 'white',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
    <Helmet>
         <meta
          charSet='utf-8'
          name='About'
          content='At Dawn Consultancy LTD, With two decades of extensive experience in general trading
                across diverse commodities, our team has cultivated an
                unparalleled expertise within various industries. Through our
                dedicated efforts, we have successfully forged a path into a
                vast reservoir of wealth, experience, and trade knowledge.'
        />
        <title>About - Dawn Consultancy LTD</title>
      </Helmet>
      <NavBar />

      <Box
        className='w-full h-96 flex justify-center shadow-lg rounded overflow-hidden'
        style={
          {
            // backgroundImage: "url('Assets/Images/about_oil_refinery.jpg')",
            // color: 'white',
            // backgroundAttachment: 'fixed',
            // background: 'black'
          }
        }
      >
        <Box
          className='flex justify-center rounded pt-20 w-full z-0'
          // style={{ background: 'black' }}
        >
          <Box className=''>
            <Typography
              className='pt-20 box'
              variant='h3'
              style={{
                fontFamily: 'Poppins',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              About Us
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        className='shadow -mt-12 overflow-hidden '
        style={{
          position: 'relative',
          zIndex: 10,
          padding: '10px',
          marginLeft: 10,
          marginRight: 10,
          width: '98%',
          // height: '200px',
          // zIndex: '2',
          background: 'inherit',
          borderRadius: 20,
        }}
      >
        <Box
          className='grid grid-cols-1 md:grid-cols-2 gap-0 p-4 z-10'
          style={{
            background: 'white',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          <div className='p-4 shadow rounded-t-md'>
            <Typography
              variant='h4'
              style={{
                fontFamily: 'Poppins',
                padding: 5,
                fontWeight: 'bold',
                // textAlign: 'center',
                // color: 'rgb(25 118 210)',
                color: '#FFA63D',
              }}
            >
              Welcome to Dawn Consultancy
            </Typography>
            <Typography
              className='box p-2'
              data-aos='fade-up'
              variant='p'
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                fontSize: '1.6rem',
                // textAlign: 'center',
                // color: 'rgb(25 118 210)',
                color: '#139c76',
              }}
            >
              Who we are?
            </Typography>
            <br />
            <br />
            <p className='text-justify text-xl '>
              With two decades of extensive experience in general trading across
              diverse commodities, our team has cultivated an unparalleled
              expertise within various industries. Through our dedicated
              efforts, we have successfully forged a path into a vast reservoir
              of wealth, experience, and trade knowledge. Collaboratively, we
              have harnessed our global presence to establish a robust network
              of business associates, enabling us to comprehensively navigate
              and engage with a substantial portion of the global trading
              landscape. Our collective strength lies in the fusion of our
              individual skills and insights, positioning us as a dynamic force
              ready to meet the challenges of the ever-evolving business world.
            </p>
          </div>
          <div
            data-aos='fade-up'
            data-aos-duration='3000'
            className='w-full p-4 shadow rounded-t-md'
          >
            <img src='Assets/Images/office_image.jpg' />
          </div>
        </Box>

        <Box
          className='grid grid-cols-1 md:grid-cols-2 gap-0 p-4'
          style={{ background: 'white' }}
        >
          <div
            data-aos='fade-up'
            data-aos-duration='3000'
            className='w-full p-4 shadow rounded-t-md'
          >
            <img src='Assets/Images/mission.jpg' />
          </div>

          <div
            className='p-4 shadow rounded-t-md'
            data-aos='fade-left'
            data-aos-duration='2000'
          >
            <Typography
              className='box p-2'
              variant='p'
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                fontSize: '1.6rem',
                // textAlign: 'center',
                // color: 'rgb(25 118 210)',
                color: '#139c76',
              }}
            >
              Our Mission?
            </Typography>
            <br />
            <br />
            <p className='text-justify text-xl'>
              At Dawn Consultancy Ltd, our overarching mission is to forge a
              global trading ecosystem of unparalleled scale, wherein
              businesses, traders, buyers, and sellers seamlessly fulfill their
              requirements with precision and adherence to specifications. Our
              commitment is to render our strategies agile, transparent, and
              cost-effective, alleviating the need for protracted planning
              horizons for buyers and sellers alike. We are dedicated to
              tailoring each business engagement according to the unique
              methodologies embraced by its proprietors. Primarily, our focus is
              on fostering trade stabilization among key regions, including the
              UK, Europe, USA, Scandinavia, and Eastern European countries, with
              the ultimate goal of unifying Asian, Arab, and African markets
              under a single, accessible platform. This ambitious vision
              necessitates a wealth of expertise, experience, knowledge,
              thorough background checks, due diligence, and face-to-face
              interactions, challenges that often impede timely deal closures
              for buyers and sellers. Dawn Consultancy Ltd stands poised to
              address these challenges, facilitating the establishment of
              mutually beneficial connections between traders, buyers, and
              sellers. Specializing in a diverse array of industries and
              products sets us apart, ensuring a distinctive and effective
              approach in the realm of global trading.
            </p>
          </div>
        </Box>

        <Box
          className='grid grid-cols-1 md:grid-cols-2 gap-0 p-4'
          style={{
            background: 'white',
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
        >
          <div
            className='p-4 shadow rounded-t-md'
            data-aos='fade-right'
            data-aos-duration='2000'
          >
            <Typography
              className='box p-2'
              data-aos='fade-up'
              variant='p'
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                fontSize: '1.6rem',
                // textAlign: 'center',
                // color: 'rgb(25 118 210)',
                color: '#139c76',
              }}
            >
              Our Vision?
            </Typography>
            <br />
            <br />
            <p className='text-justify text-xl'>
              At Dawn Consultancy Ltd, we aspire to carve a prominent global
              identity renowned for its unparalleled expertise, profound
              insights into the intricacies of the business world, unwavering
              commitment to transparency, and the unique ability to effortlessly
              bridge geographical distances. Our vision is to redefine the
              landscape of international commerce, simplifying the path for
              entrepreneurs to conceptualize and establish businesses without
              the constraints of borders or physical offices. We envision a
              future where industries seamlessly converge with their respective
              buyers and sellers, fostering an environment where collaboration
              knows no hesitation. While our vision is ambitious, at Dawn
              Consultancy Ltd, we embrace the belief that no challenge is
              insurmountable, propelling us towards the realization of our
              expansive aspirations.
            </p>
          </div>
          <div
            data-aos='fade-up'
            data-aos-duration='3000'
            className='w-full p-4 shadow rounded-t-md'
          >
            <img src='Assets/Images/vision.jpg' />
          </div>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default About;
