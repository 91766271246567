import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Metal() {
  const metal = [
    {
      category: 'Ferrous',
      description: 'Ferrous discription',
      image: 'Assets/Images/Metals/ferrous.jpg',
      // to: '/ecommerce',
    },
    {
      category: 'Non-Ferrous',
      description: 'Non-Ferrous discription',
      image: 'Assets/Images/Metals/non_ferrous.png',

      // to: '/prime_plastics',
    },
    {
      category: 'Scrap',
      description: 'Scrap discription',
      image: 'Assets/Images/Metals/scrap.jpg',

      // to: '/petrochemicals',
    },
    // {
    //   category: 'Polystyrene',
    //   description: 'Polystyrene discription',
    //   image: 'Assets/Images/Prime_Plastics/polystyrene.jpg',

    //   // to: '/metal',
    // },
    // {
    //   category: 'Polypropylene',
    //   description: 'Polypropylene discription',
    //   image: 'Assets/Images/Prime_Plastics/polypropylene.jpg',

    //   // to: '/foods',
    // },
  ];
  const location = useLocation();
  const [data, setdata] = useState(location.state?.category);
  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <Box className='w-full bg-slate-100'>
      <Box
        className='w-full h-96  flex justify-center shadow-lg rounded'
        style={{
          color: 'white',
          backgroundImage: "url('Assets/Images/melting_metal.jpg')",
          backgroundAttachment: 'fixed',
        }}
      >
        <Box
          className='flex justify-center rounded pt-20 w-full opacity-50'
          style={{ background: 'black' }}
        >
          <Box className=''>
            <Typography
              className='pt-20 box'
              variant='h3'
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              Metal Store
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        className='border shadow -mt-12 z-40 overflow-hidden '
        style={{
          position: 'relative',
          zIndex: 10,
          padding: '10px',
          marginLeft: 10,
          marginRight: 10,
          width: '98%',
          // height: '200px',
          // zIndex: '2',
          background: 'white',
          borderRadius: 20,
        }}
      >
        <Box className=''>
          <Typography
            className='p-8'
            variant='h4'
            style={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
            }}
          >
            Featured Categories
          </Typography>
        </Box>
        <Box
          data-aos='fade-down'
          className='flex grid sm:justify-center justify-center lg:grid-cols-3 md:grid-cols-2 p-4 gap-10 '
        >
          {metal.map((value, index) => {
            return (
              <div className='card' key={index}>
                <img
                  src={value.image}
                  data-aos='fade-up'
                  className='w-full h-full rounded-full'
                />
                <div className='card-body text-center w-full h-full'>
                  <h2>{value.category}</h2>
                  <p>{value.description}</p>
                </div>
              </div>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default Metal;
