import { Box, Typography } from '@mui/material';
import React from 'react';
// import ReactImageGallery from 'react-image-gallery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import Slider from '../Slider/Slider';
import { Helmet } from 'react-helmet';

AOS.init();

function Dashboard() {
  const images = [
    {
      original: 'Assets/Images/oil_refinery.jpg',
      description: 'Oil Refinery',
    },
    {
      original: 'Assets/Images/recycled_waste.jpg',
      description: 'Waste Plastic',
    },
    {
      original: 'Assets/Images/company.jpg',
      description: 'Factory site',
    },
    {
      original: 'Assets/Images/cloths_making.jpg',
      description: 'Cloths Designing',
    },
    {
      original: 'Assets/Images/melting_metal.jpg',
      description: 'Melting metal',
    },
    {
      original: 'Assets/Images/tech_it.jpg',
      description: 'Technology',
    },
    {
      original: 'Assets/Images/chemicals.jpg',
      description: 'Chemicals',
    },
    // {
    //   original: 'Assets/Images/company.jpg',
    //   description: 'Image 1'

    // },
    {
      original: 'Assets/Images/ecommerce_cart.jpg',
      description: 'Ecommerce',
    },
    //  {
    //   original: 'Assets/Images/ecommerce.jpg',
    //   description: 'Image 1'

    // },
    {
      original: 'Assets/Images/meeting_business.jpg',
      description: 'Business Consultancy',
    },
    {
      original: 'Assets/Images/rolls_febric.jpg',
      description: 'Cloths rolls',
    },
  ];

  const business = [
    {
      category: 'Ecommerce',
      description:
        'We can help you to establish business on following platforms, Amazon, ebay, shopify, Etsy, Tiktok.',
      image: 'Assets/Images/ecommerce.jpg',
      sub_category: [
        {
          category: 'Cloths',
          description: 'Cloths Description',
          image: 'Assets/Images/cloths.jpg',
        },
        {
          category: 'Footware',
          description: 'Footware Description',
          image: 'Assets/Images/footware.jpg',
        },
        {
          category: 'Health and Beauty',
          description: 'Health and beauty Description',
          image: 'Assets/Images/health_beauty.jpg',
        },
        {
          category: 'Hand made products',
          description: 'Hand made products Description',
          image: 'Assets/Images/handmade.jpg',
        },
        {
          category: 'Home accessories',
          description: 'Home accessories Description',
          image: 'Assets/Images/accessories.jpg',
        },
        {
          category: 'Gardening',
          description: 'Gardening Description',
          image: 'Assets/Images/gardening.jpg',
        },
        {
          category: 'Electronic',
          description: 'Electronic Description',
          image: 'Assets/Images/tech_it.jpg',
        },
        {
          category: 'Decoration',
          description: 'Decoration Description',
          image: 'Assets/Images/decoration.jpg',
        },
      ],
      // to: '/ecommerce',
      to: '/business',
    },
    {
      category: 'Plastics',
      description:
        'With experience of 20 years of importing and exporting prime and recycled plastic material around the globe, we can establish for you or can provide you any material accoridng to your specification and demands in your country with our import and exports skills including logistics experties.',
      image: 'Assets/Images/abs-banner.jpg',
      sub_category: [],
      // to: '/prime_plastics',
      to: '/business',
    },
    {
      category: 'Petrochemicals',
      description:
        'We have huge supply of petrochemicals products available such as crude oil and bitumen on contract bases.',
      image: 'Assets/Images/oil_refinery.jpg',
      sub_category: [],
      // to: '/petrochemicals',
      to: '/business',
    },

    {
      category: 'Mining',
      description:
        'We have vast experience in mining business in African and Asian continents. We are working with miners in ore and refined products.',
      image: 'Assets/Images/mining.jpg',
      // to: '/mining',
      to: '/business',
    },
    {
      category: 'Precious Gems Stones',
      description:
        'We can supply or connect you with precious gems stones suppliers from different countries in precious gem market',
      image: 'Assets/Images/gems.jpg',
      // to: '/gems_stones',
      to: '/business',
    },
    {
      category: 'Metal',
      description:
        'We are dealing Ferrous, Non-ferrous and scrape metals according to buyer and seller demands and specifications.',
      image: 'Assets/Images/melting_metal.jpg',
      sub_category: [],
      // to: '/metal',
      to: '/business',
    },
    {
      category: 'Foods',
      description:
        'We have our experties in Spicies, Cooking oil, Suger, Rice and Wheat. we also specialized in hemaliyan salt or pink salt products',
      image: 'Assets/Images/trading.jpg',
      sub_category: [],
      // to: '/foods',
      to: '/business',
    },
    ,
    {
      category: 'General Trading',
      description:
        'We specialized in general tarading where we almost cover 80% of general trading import and export. In this platform, we cover almost all industries and there products and unique desgin of trading where majority of the buyers and sellers can be entertained.',
      image: 'Assets/Images/foods.jpg',
      sub_category: [],
      // to: '/trading',
      to: '/business',
    },
    // {
    //   category: 'Auto Industry',
    //   description: 'We provide assitance to import and export cars and SUVs',
    //   image: 'Assets/Images/auto_industry.jpg',
    //   sub_category: [],
    //   // to: '/auto_industry',
    //   to: '/business',
    // },
    // {
    //   category: 'Property',
    //   description: 'We can connect you with reputable developers and dealer in these countries : UK, Ireland ,Spain, Italy, Portugal, Belgium, Netherland, ',
    //   image: 'Assets/Images/property.jpg',
    //   sub_category: [],
    //   // to: '/property',
    //   to: '/business',
    // },
  ];

  return (
    <>
      <Helmet>
        <meta
          charSet='utf-8'
          name='Home'
          content='Dawn Consultancy stands poised to address these challenges, facilitating the establishment of
           mutually beneficial connections between traders, buyers, and sellers.
          Our overarching mission is to forge a
           global trading ecosystem of unparalleled scale,
            wherein businesses, traders, buyers, and sellers 
            seamlessly fulfill their requirements with precision and adherence to specifications.'
        />
        <title>Home - Dawn Consultancy LTD</title>
      </Helmet>
      <Box
        className=' w-full'
        style={{
          // zIndex: 2,
          backgroundImage: "url('Assets/Images/consultancy.jpg')",
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <NavBar />

        <Box className='w-full justify-center overflow-hidden'>
          <Slider className='shadow' />

          <Box
            className='grid grid-cols-1 lg:grid-cols-2 shadow w-full p-4'
            // data-aos='fade-left'
            // data-aos-duration='3000'
            style={{ position: 'relative', background: 'white' }}
          >
            <div
              className='lg:py-12 p-8'
              data-aos='fade-up'
              data-aos-duration='3000'
            >
              <img
                src='Assets/Images/office_image.jpg'
                style={{ width: '90%', borderRadius: 20 }}
              />
            </div>
            <div
              style={{ borderRadius: 20 }}
              data-aos='fade-left'
              // className='p-8 flex w-full lg:h-full justify-center '
              data-aos-duration='2000'
            >
              <Typography
                variant='h4'
                style={{
                  fontFamily: 'Poppins',
                  padding: 5,
                  fontWeight: 'bold',
                  // textAlign: 'center',
                  // color: 'rgb(25 118 210)',
                  color: '#FFA63D',
                }}
              >
                Welcome to Dawn Consultancy
              </Typography>
              <Typography
                className='box p-2'
                data-aos='fade-up'
                variant='p'
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                  fontSize: '1.8rem',
                  // textAlign: 'center',
                  // color: 'rgb(25 118 210)',
                  color: '#139c76',
                }}
              >
                About Us
              </Typography>
              <br />
              <br />
              <p className='text-justify text-2xl '>
                With two decades of extensive experience in general trading
                across diverse commodities, our team has cultivated an
                unparalleled expertise within various industries. Through our
                dedicated efforts, we have successfully forged a path into a
                vast reservoir of wealth, experience, and trade knowledge.
                Collaboratively, we have harnessed our global presence to
                establish a robust network of business associates, enabling us
                to comprehensively navigate and engage with a substantial
                portion of the global trading landscape. Our collective strength
                lies in the fusion of our individual skills and insights,
                positioning us as a dynamic force ready to meet the challenges
                of the ever-evolving business world.
              </p>
              <div className='uppercase w-full mt-8'>
                <Button
                  as={Link}
                  to={'/about'}
                  variant='outline-success'
                  // onClick={() => alert('Submitted')}
                  style={{
                    fontWeight: 'bold',
                    borderColor: '#DAA520',
                    fontFamily: 'poppins',
                  }}
                  className='uppercase'
                >
                  Read more
                </Button>
              </div>
            </div>
          </Box>

          {/* <Box
          className='shadow w-full flex justify-center p-2 mt-4 border'
          // data-aos='zoom-in'
          // data-aos-duration='3000'
          style={{ borderRadius: 20 }}
        >
          <Typography
            variant='p'
            className='box p-2'
            style={{
              fontFamily: 'Poppins',
              padding: 5,
              fontSize: '2.0rem',
              fontWeight: 'bold',
              textAlign: 'center',
              color: 'rgb(25 118 210)',
            }}
          >
            Overview
          </Typography>
        </Box> */}
          <Box
            className='shadow w-full p-2'
            // data-aos='zoom-in'
            // data-aos-duration='3000'
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              background: 'white',
            }}
          >
            <Box className='p-8'>
              <Typography
                variant='h4'
                className='text-xl'
                style={{
                  color: '#FFA63D',
                  fontFamily: 'poppins',
                  fontWeight: 'bold',
                }}
              >
                We provide Consultancy in these
              </Typography>
              <Typography
                variant='p'
                className='box p-2'
                style={{
                  fontFamily: 'Poppins',
                  padding: 5,
                  fontSize: '2.3rem',

                  fontWeight: 'bold',
                  textAlign: 'center',
                  // color: 'rgb(25 118 210)',
                  color: '#139c76',
                }}
              >
                Businesses
              </Typography>
            </Box>
            <Box
              data-aos='fade-down'
              className='flex grid sm:justify-center justify-center lg:grid-cols-3 md:grid-cols-2 p-4 gap-10 '
            >
              {business.map((value, index) => {
                return (
                  <div key={index}>
                    <Link
                      to={value.to}
                      className='card'
                      style={{ textDecoration: 'none' }}
                    >
                      <img
                        src={value.image}
                        data-aos='fade-up'
                        className='w-full h-full'
                      />
                      <div className='card-body'>
                        <p className='text-2xl' style={{ fontWeight: 'bold' }}>
                          {value.category}
                        </p>
                        <p className='text-justify'>{value.description}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </Box>
          </Box>

          <Box
            className='grid grid-cols-1 lg:grid-cols-2 shadow w-full p-4'
            // data-aos='fade-left'
            // data-aos-duration='3000'
            style={{ position: 'relative', background: 'white' }}
          >
            <div className='lg:py-12 p-4'>
              <Typography
                variant='p'
                className='text-2xl'
                style={{
                  color: '#FFA63D',
                  fontFamily: 'poppins',
                  fontWeight: 'bold',
                }}
              >
                Popular Category
              </Typography>
              <Typography
                variant='h4'
                style={{
                  color: '#139c76',
                  fontFamily: 'poppins',
                  fontWeight: 'bold',
                }}
              >
                Information Technology (IT)
              </Typography>
              <p
                className='text-2xl text-justify'
                style={{ fontFamily: 'poppins', marginTop: 10 }}
              >
                At <b>Dawn Consultancy LTD</b>, We provide consultancy in, How
                you can design your website according to business needs and How
                to Set up your social media accounts for business. We also have
                a team of Websites designers who can setup your business website
                and social media platform according to your needs and demands.
              </p>
            </div>
            <div
              style={{ borderRadius: 20 }}
              data-aos='fade-left'
              className='p-8 flex w-full lg:h-full justify-center '
              data-aos-duration='2000'
            >
              <div
              // className='flex shadow img_slider  '
              // style={{ borderRadius: 20 }}
              >
                <img
                  style={{ borderRadius: 20, width: '100%' }}
                  src='Assets/Images/information_technology.jpg'
                  className='animated'
                />
              </div>
            </div>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default Dashboard;
