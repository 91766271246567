import { Link, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Dashboard from './Components/Dashboard/Dashboard';
import Business from './Components/Business/Business';
import Footer from './Components/Footer/Footer';
import Ecommerce from './Components/Business/Categories/Ecommerce';
import Prime_plastics from './Components/Business/Categories/Prime_plastics';
import Petrochemicals from './Components/Business/Categories/Petrochemicals';
import Foods from './Components/Business/Categories/Foods';
import Metal from './Components/Business/Categories/Metal';
import Auto_industry from './Components/Business/Categories/Auto_industry';
import Property from './Components/Business/Categories/Property';
import { Box } from '@mui/material';
import NavBar  from './Components/NavBar/NavBar'

function App() {
  return (
    // <Box
    //   className='border'
    //   style={{
    //     backgroundImage: "url('Assets/Images/business_consultancy.jpg')",
    //     backgroundAttachment: 'fixed',
    //   }}
    <>
    {/* <NavBar /> */}
      <Routes>
        <Route path='/' exact Component={Dashboard} />
        <Route path='/about' exact Component={About} />
        <Route path='/contact' exact Component={Contact} />
        <Route path='/business' exact Component={Business} />
        <Route path='/ecommerce' exact Component={Ecommerce} />
        <Route path='/prime_plastics' exact Component={Prime_plastics} />
        <Route path='/petrochemicals' exact Component={Petrochemicals} />
        <Route path='/foods' exact Component={Foods} />
        <Route path='/metal' exact Component={Metal} />
        <Route path='/auto_industry' exact Component={Auto_industry} />
        <Route path='/property' exact Component={Property} />
      </Routes>
      {/* <Footer /> */}
      {/* <Link
        exact
        className=''
        style={{
          fontFamily: 'poopins',
          position: 'fixed',
          bottom: 20,
          right: 20,

          fontWeight: 'bold',
        }}
        to='/contact'
        className='shadow flex justify-center send_inquiry_btn p-3 nav-link text-white'
        // variant='contained'
      >
        Send <br /> Inquiry
      </Link> */}
    </>
  );
}

export default App;
