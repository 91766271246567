import { Box, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { pink } from '@mui/material/colors';
import { Helmet } from 'react-helmet';

function BasicExample() {
  return (
    <>
      {/* <Box
        style={{
          background: 'rgb(56 189 248)',
          // background:'inherit',
          fontFamily: 'poppins',
          color: 'white',
          borderBottomLeftRadius:20,
          borderBottomRightRadius:20,

        }}
        className='p-12'
      >
        <Typography
                variant='p'
                className='box p-2'
                style={{
                  fontFamily: 'Poppins',
                  padding: 5,
                  fontSize: '2.0rem',

                  fontWeight: 'bold',
                  textAlign: 'center',
                  // color: 'rgb(25 118 210)',
                }}
              >
                Our Partners
              </Typography>
         <p data-aos="fade-up-Left" style={{ fontFamily: 'poppins', textAlign:'center', marginTop: 10 }}>
              Our Service is free for new Startups
            </p>
        <Link
        data-aos="fade-up"
          className='flex justify-center transition ease-in-out delay-15'
          style={{
            textDecoration: 'none',
          }}
        >
          <span
            style={{
              fontFamily: 'poopins',
              borderRadius: 20,
              fontWeight: 'bold',
              padding: 10,
            }}
            variant='contained'
            className='btn_login_signup'
          >
            Get Started
          </span>
        </Link>
      </Box> */}
      <Box
        style={{
          // background: 'rgb(25 118 210)',
          // background:'inherit',
          fontFamily: 'poppins',
          color: 'white',
        }}
        className='p-12'
      >
        <Typography
          variant='h5'
          data-aos='fade-up-right'
          style={{
            color: 'white',
            fontFamily: 'poppins',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Get Started with Dawn Consultancy Now
        </Typography>
        <p
          data-aos='fade-up-Left'
          style={{ fontFamily: 'poppins', textAlign: 'center', marginTop: 10 }}
        >
          Our Service is free for new Startups
        </p>
        <Link
          data-aos='fade-up'
          className='flex justify-center transition ease-in-out delay-15'
          style={{
            textDecoration: 'none',
          }}
        >
          <span
            style={{
              fontFamily: 'poopins',
              borderRadius: 20,
              fontWeight: 'bold',
              padding: 10,
            }}
            variant='contained'
            className='btn_login_signup'
          >
            Get Started
          </span>
        </Link>
      </Box>
      <Box
        style={{ fontFamily: 'poppins', color: 'white' }}
        className='footer_bg_color'
      >
        <Box className='grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-5'>
          <div style={{ border: '0px solid white' }} className='m-4 p-4'>
            <img
              src={'Assets/DAWN.png'}
              alt='Not found'
              className='w-36 h-28 sm:w-52 sm:h-36 mb-8'
              // style={{ width: '50%', height: '40%' }}
            />
            <p varient='h5'>A global Platform for New startups</p>
          </div>
          <div style={{ border: '0px solid white' }} className='m-4 p-4 '>
            <Typography
              variant='h5'
              style={{
                fontWeight: 'bold',
                fontFamily: 'poppins',
                paddingBottom: 10,
                color: 'white',
              }}
            >
              Quick Links
            </Typography>
            <Divider />
            <Box className='p-2 pt-4'>
              <div>
                <DashboardIcon className='mr-3' />
                <Link
                  to={'/'}
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  Home
                </Link>
              </div>
              <div className='mt-3'>
                <BusinessIcon className='mr-3' />
                <Link
                  to={'/business'}
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  Business
                </Link>
              </div>
              <div className='mt-3'>
                <InfoIcon className='mr-3' />
                <Link
                  to={'/about'}
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  About
                </Link>
              </div>
              <div className='mt-3'>
                <ContactPageIcon className='mr-3' />
                <Link
                  to='/contact'
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  Contact
                </Link>
              </div>
            </Box>
          </div>

          <div style={{ border: '0px solid white' }} className='m-4 p-4'>
            <Typography
              variant='h5'
              style={{
                fontWeight: 'bold',
                fontFamily: 'poppins',
                paddingBottom: 10,
                color: 'white',
              }}
            >
              Contact Us
            </Typography>
            <Divider />
            <Box className='p-2 pt-4'>
              <div className='flex'>
                <LocationOnIcon className='mr-3' />
                <Link
                  //   to={'/'}
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  Room 4, ID Business Center, Brunel house, RTC Center, London
                  Road,Derby,DE24 8UP, United kingdom.
                </Link>
              </div>
              <div className='mt-3 flex'>
                <PhoneIcon className='mr-3' />
                <a
                  href='tel:+44 1332 957986'
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  {' '}
                  +44 1332 957986
                </a>
              </div>
              <div className='mt-3 flex'>
                <MailIcon className='mr-3' />
                <a
                  href='mailto:info@dawnconsultancyltd.com'
                  //   to={'/about'}
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  info@dawnconsultancyltd.com
                </a>
              </div>
              <div className='mt-3 flex'>
                <WhatsAppIcon className='mr-3' />

                <a
                  href='tel:+44 79855 10267'
                  //   to="/contact"
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontFamily: 'poppins',
                    fontSize: '1.1rem',
                  }}
                >
                  +44 79855 10267
                </a>
              </div>
            </Box>
          </div>
        </Box>
        <Divider style={{ width: '90%', marginLeft: '5%' }} />
        <Box className='grid md:grid-cols-2 grid-cols-1 md:gap-80 pl-8'>
          <div className='m-4'>
            <Typography
              variant='p'
              className=''
              style={{
                fontWeight: 'bold',
                fontFamily: 'poppins',
                paddingBottom: 10,
                fontSize: '0.9rem',
              }}
            >
              Copyright © 2024 DAWN CONSULTANCY LTD All Rights Reserved
            </Typography>
          </div>

          <div className='p-4'>
            <span
              className='mr-4 pb-1'
              style={{ background: 'white', borderRadius: 10 }}
            >
              <FacebookIcon
                // id='facebook'
                color='primary'
                onClick={() => alert('hello')}
                style={{ fontSize: '1.2rem', cursor: 'pointer' }}
              />
            </span>
            <span
              className='mr-4 pb-1'
              style={{ background: 'white', borderRadius: 10 }}
            >
              <LinkedInIcon
                className=''
                color='primary'
                // id='linkedin'
                onClick={() => alert('hello')}
                style={{ fontSize: '1.2rem', cursor: 'pointer' }}
              />
            </span>
            <span
              className='mr-4 pb-1'
              style={{ background: 'white', borderRadius: 10 }}
            >
              <TwitterIcon
                className=''
                // id='twitter'
                color='primary'
                onClick={() => alert('hello')}
                style={{ fontSize: '1.2rem', cursor: 'pointer' }}
              />
            </span>

            <span
              className='mr-4 pb-1'
              style={{ background: 'white', borderRadius: 10 }}
            >
              <InstagramIcon
                className=''
                // id='instagram'
                onClick={() => alert('hello')}
                sx={{ color: pink[500] }}
                style={{ fontSize: '1.2rem', cursor: 'pointer' }}
              />
            </span>
            {/* <span
              className='mr-4 pb-1'
              style={{ background: 'white', borderRadius: 10 }}
            >
              <YouTubeIcon
                className=''
                // id='instagram'
                sx={{ color: pink[900] }}
                onClick={() => alert('hello')}
                style={{ fontSize: '1.2rem', cursor: 'pointer' }}
              />
            </span> */}
          </div>
        </Box>
      </Box>
    </>
  );
}

export default BasicExample;
