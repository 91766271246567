import { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem, { listItemclassNamees } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Dashboard from '../Dashboard/Dashboard';
import About from '../About/About';
import Contact from '../Contact/Contact';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Search from '../Searchbar/search';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RecyclingIcon from '@mui/icons-material/Recycling';
import ScienceIcon from '@mui/icons-material/Science';
import CategoryIcon from '@mui/icons-material/Category';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Account from '../Business/Business';
import { Button, TextField } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import MenuBar from './MenuBar';
import Navdropdown from './Navdropdown';
var drawerWidth = 240;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function SideDrawer() {
  const [open, setOpen] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Box
        className='w-full z-40 '
        style={{ position: 'sticky', top: 0, zIndex: 99 }}
        id='sticky-header'
      >
        <Box className='w-full z-40'>
          <div
            style={{ color: 'white'}}
            className='bg_color flex flex-wrap items-center justify-between p-2 pb-4'
          >
            <div className='flex flex-wrap items-center justify-between'>
              <div className='flex ml-16 pt-2'>
                <MailIcon className='mr-2' style={{ fontSize: '1.2rem' }} />
                <Typography className='text-base'>
                  <a href='mailto:info@dawnconsultancyltd.com' style={{textDecoration:'none',color:'white'}}>info@dawnconsultancyltd.com</a>
                </Typography>
              </div>
              <div className='flex ml-16 pt-2'>
                <PhoneIcon className='mr-2' style={{ fontSize: '1.2rem' }} />
                <Typography className='text-base'>
                   <a href='tel:+44 1332 957986' style={{textDecoration:'none',color:'white'}}>+44 1332 957986</a> 
                </Typography>
              </div>
            </div>
            <div className='flex flex-wrap items-center justify-between pr-12 ml-16 pt-2 sm:ml-16'>
              <FacebookIcon
                className=' text-lg mr-4 animate-pulse'
                id='facebook'
                onClick={() => alert('hello')}
                style={{ cursor: 'pointer' }}
              />
              <LinkedInIcon
                className='text-lg mr-4 animate-pulse'
                id='linkedin'
                onClick={() => alert('hello')}
                style={{ cursor: 'pointer' }}
              />
              <TwitterIcon
                className='text-lg mr-4 animate-pulse'
                id='twitter'
                onClick={() => alert('hello')}
                style={{ cursor: 'pointer' }}
              />
              <InstagramIcon
                className='text-lg animate-pulse'
                id='instagram'
                onClick={() => alert('hello')}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
          {/* <Box
            className='flex flex-wrap items-center justify-between p-3 pl-8'
            style={{ color: 'white', background: 'rgb(25 118 210)' }}
          > */}
        </Box>

        {/* <nav
          style={{ background: 'rgb(25 118 210)' }}
          className='pl-3 pr-3 pb-3'
        >
          <div className='flex items-center justify-around'>
            <div className='z-50 md:w-auto w-full flex justify-between'>
              <Typography
                variant='h5'
                style={{
                  color: 'white',
                  fontFamily: 'poppins',
                  fontWeight: 'bold',
                }}
              >
                Dawn Consultancy
              </Typography>
              <div
                className='text-3xl md:hidden'
                onClick={() => setOpen(!open)}
              >
                <ion-icon name={`${open ? 'close' : 'menu'}`}></ion-icon>
              </div>
            </div>
            <ul
              variant={'h6'}
              className='md:flex hidden uppercase items-center font-[Poppins] mt-4'
            >
              <li>
                <Link
                  to='/'
                  className='px-3 inline-block text-white'
                  style={{ textDecoration: 'none' }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className='px-3 inline-block text-white'
                  style={{ textDecoration: 'none' }}
                >
                  <Navdropdown />
                </Link>
              </li>
              <li>
                <Link
                  to='/about'
                  className='px-3 inline-block text-white'
                  style={{ textDecoration: 'none' }}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to='/contact'
                  className='px-3 inline-block text-white'
                  style={{ textDecoration: 'none' }}
                >
                  Contact
                </Link>
              </li>

              <li 
              className='inline-block text-white'>
                <Link
                  className='transition ease-in-out delay-15'
                  style={{ textDecoration: 'none' }}
                >
                  <span
                    style={{
                      fontFamily: 'poopins',
                      borderRadius: 20,
                      fontWeight: 'bold',
                      padding: 10,
                    }}
                    variant='contained'
                    className='btn_login_signup'
                  >
                    Chat With Us
                  </span>
                </Link>
              </li>
            </ul>
            <div className='md:block hidden'>
              <Button />
            </div>
            {/* Mobile nav */}
        {/* <ul
              className={`
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? 'left-0' : 'left-[-100%]'}
        `}
            >
              <li>
                <Link to='/' className='py-7 px-3 inline-block'>
                  Home
                </Link>
              </li>
              <Navdropdown />
              <div className='py-5'>
                <Button />
              </div>
            </ul>
          </div>
        </nav> */}

        <MenuBar />
      </Box>
    </>
  );
}
