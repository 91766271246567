import React from 'react';
import { Carousel } from 'react-bootstrap';

function Slider() {
  const slider_img = [
    {
      title: 'Prime and Recycled Plastics',
      description:
        ' Prime and Recycled Plastics Consultancy. Elevate your business with us in the dynamic world of polymer innovation',
      image: 'Assets/Images/Slider Images/abs-banner.jpg',
    },
    {
      title: 'Petrochemicals',
      description:
        'From refining strategies to optimizing growth, we are your compass in the dynamic world of petrochemicals.',
      image: 'Assets/Images/Slider Images/oil_refinery.jpg',
    },
    {
      title: 'Mining',
      description: 'We invite all the miners around the globe to introduce there finished or unfinished products at our platform',
      image: 'Assets/Images/Slider Images/mining.jpg',
    },

    {
      title: 'Precious Gems Stones',
      description: 'To get the right value for the right stone, let us help you and guide you',
      image: 'Assets/Images/Slider Images/gems.jpg',
    },
    {
      title: 'Metal',
      description:
        'Uncover pathways to success with our tailored solutions for ferrous, non-ferrous, and scrap metals.',
      image: 'Assets/Images/Slider Images/melting_metal.jpg',
    },
    {
      title: 'Ecommerce',
      description:
        'Strategic Guidance for Digital Dominance. Unleash your brands online potential with our expert consultants',
      image: 'Assets/Images/Slider Images/ecom.png',
    },
    {
      title: 'Foods',
      description: 'We have our experties in Spicies, Cooking oil, Suger, Rice and Wheat.',
      image: 'Assets/Images/Slider Images/foods.jpg',
    },
    // {
    //   title: 'Auto industry',
    //   description: 'We provide assitance to import and export cars and SUVs',
    //   image: 'Assets/Images/Slider Images/auto_industry.jpg',
    // },
    // {
    //   title: 'PGM',
    //   description: 'Your Partner in PGM Success.',
    //   image: 'Assets/Images/Slider Images/pgm.png',
    // },
  ];

  return (
    <Carousel fade className='shadow' prevLabel={null} nextLabel={null}>
      {slider_img.map((item, index) => {
        return (
          <Carousel.Item>
            <img style={{width:'100%'}} src={item.image} text={item.title} />
            <Carousel.Caption>
              <h3>{item.title}</h3>      
              <p className='text-xl md:text-2xl d-none d-sm-block'>{item.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
    // <div>
    // <nav class="slides-nav">
    // 				<button class="slides-nav__item slides-nav__item--prev">&larr;</button>
    // 				<button class="slides-nav__item slides-nav__item--next">&rarr;</button>
    // 			</nav>
    //             <div class="slides">
    // 			<div class="slide">
    // 				<div class="slide__img" style="background-image:url(img/1.jpg)"></div>
    // 			</div>
    // 			<div class="slide">
    // 				<div class="slide__img" style="background-image:url(img/2.jpg)"></div>
    // 			</div>
    // 			<div class="slide">
    // 				<div class="slide__img" style="background-image:url(img/3.jpg)"></div>
    // 			</div>
    // 			<div class="slide">
    // 				<div class="slide__img" style="background-image:url(img/4.jpg)"></div>
    // 			</div>
    // 			<div class="slide">
    // 				<div class="slide__img" style="background-image:url(img/5.jpg)"></div>
    // 			</div>
    // 		</div>
    // </div>
  );
}

export default Slider;
