import { Box, FormControl, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Business_dropdown from './Business_dropdown';
import Button from 'react-bootstrap/Button';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import emailjs from '@emailjs/browser';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { Select as BaseSelect, selectClasses } from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { OptionGroup as BaseOptionGroup } from '@mui/base/OptionGroup';
import { Popper as BasePopper } from '@mui/base/Popper';
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { Helmet } from 'react-helmet';

function Contact() {
  const form = useRef();
  const [name, setname] = useState('');
  const [business, setbusiness] = useState('');
  const [email, setemail] = useState('');
  const [message, setmessage] = useState('');

  const [form_check, setform_check] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);

    emailjs
      .sendForm(
        'service_4du82ht',
        'template_w8rt4g7',
        form.current,
        'p1pLUar4Ik_g1x1Ph'
      )
      .then(
        (result) => {
          setform_check(true);
          setname('');
          setemail('');
          setmessage('');
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      if (form_check) {
        setform_check(false);
      }
    }, 5000);
    return () => {
      clearTimeout(timeId);
    };
  }, [form_check]);

  return (
    <Box
      className=' w-full'
      style={{
        backgroundImage: "url('Assets/Images/Contact_us.jpg')",
        // color: 'white',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
    <Helmet>
       <meta
          charSet='utf-8'
          name='Contact'
          content='At Dawn Consultancy LTD, With two decades of extensive experience in general trading
                across diverse commodities, our team has cultivated an
                unparalleled expertise within various industries. Through our
                dedicated efforts, we have successfully forged a path into a
                vast reservoir of wealth, experience, and trade knowledge.'
        />
        <title>Contact - Dawn Consultancy LTD</title>
      </Helmet>
      <NavBar />
      <Box
        className='w-full h-96 flex justify-center shadow-lg rounded overflow-hidden'
        style={{
          color: 'white',
          // backgroundImage: "url('Assets/Images/Contact_us.jpg')",
          // backgroundAttachment: 'fixed',
        }}
      >
        <Box className='flex justify-center rounded pt-20 w-full'>
          <Box className=''>
            <Typography
              className='pt-20 box'
              variant='h3'
              style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              Contact Us
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        className='shadow -mt-12 mb-4 '
        style={{
          position: 'relative',
          zIndex: 10,
          // padding: '5px',
          marginLeft: 10,
          marginRight: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          width: '98%',
          // height: '200px',
          // zIndex: '2',
          background: 'white',
        }}
      >
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2406.112426542207!2d-1.463604218249013!3d52.91040354574655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879f101eb8a9be5%3A0x28c4a3a5dcfaa217!2sBrunel%20House!5e0!3m2!1sen!2suk!4v1704107857256!5m2!1sen!2suk'
          className='w-full'
          height='600'
          style={{
            border: 0,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          allowfullscreen=''
          loading='lazy'
          referrerpolicy='no-referrer-when-downgrade'
        ></iframe>
      </Box>
      <Box
        className='grid grid-cols-1 md:grid-cols-2 shadow  mb-4 '
        style={{
          // position: 'relative',
          // zIndex: 10,
          // padding: '10px',
          marginLeft: 5,
          marginRight: 5,
          // width: '98%',
          // height: '200px',
          // zIndex: '2',
          background: 'white',
          borderRadius: 20,
        }}
      >
        <div
          className='w-full shadow rounded-t-md'
          style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
        >
          <img
            src='Assets/Images/email-6370595_1920.jpg'
            style={{ borderTopLeftRadius: 20 }}
          />
        </div>
        <div
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete='off'
          className='shadow p-4'
          style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20 }}
        >
          <Typography
            variant='h4'
            style={{
              fontFamily: 'Poppins',
              padding: 5,
              fontWeight: 'bold',
              textAlign: 'center',
              // color: 'rgb(25 118 210)',
              color: '#FFA63D',
            }}
          >
            Get in Touch with us!
          </Typography>
          <form
            ref={form}
            onSubmit={sendEmail}
            className='w-full'
            defaultValue=''
            required
          >
            {/* <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" /> */}
            <div className='w-full'>
              <TextField
                label='Enter Your Name'
                name='user_name'
                value={name}
                onChange={(e) => setname(e.target.value)}
                required
                style={{ width: '95%', margin: '2%' }}
                // multiline
                // maxRows={4}
              />
            </div>
            <div className='w-full '>
              {/* <Business_dropdown /> */}

              <Select
                isRequired
                name='user_business'
                onChange={(e) => setbusiness(e.target.value)}
                placeholder='Choose a Business'
                //   style={{ margin: '1%' }}
              >
                <OptionGroup label='Information Technology (IT)'>
                  <Option value='Website Consultancy'>
                    Website Consultancy
                  </Option>
                  <Option value='Social Media Accounts'>
                    Social Media Accounts
                  </Option>
                </OptionGroup>
                <OptionGroup label='Ecommerce'>
                  <Option value='Clothing'>Clothing</Option>
                  <Option value='Footware'>Footware</Option>
                  <Option value='Health'>Health and Beauty</Option>
                  <Option value='Handmade'>Handmade products</Option>
                  <Option value='accessories'>Home accessories</Option>
                  <Option value='Gardining'>Gardining</Option>
                  <Option value='Electronic'>Electronic</Option>
                  <Option value='Decoration'>Decoration</Option>
                </OptionGroup>
                <OptionGroup label='Precious Gems Stones'>
                  <Option value='Diamond'>Diamond</Option>
                  <Option value='Ruby'>Ruby</Option>
                  <Option value='Sapphire'>Sapphire</Option>
                  <Option value='Emeradl'>Emeradl</Option>
                </OptionGroup>
                <OptionGroup label='petrochemicals'>
                  <Option value='Petroleum'>Petroleum</Option>
                  <Option value='Fuels'>Fuels</Option>
                </OptionGroup>
                <OptionGroup label='PGMs Mining'>
                  <Option value='Platinum'>Platinum </Option>
                  <Option value='Iridium'>Iridium</Option>
                  <Option value='Osmium'>Osmium</Option>
                  <Option value='Palladium'>Palladium</Option>
                  <Option value='Rhodium'>Rhodium</Option>
                  <Option value='Ruthenium'>Ruthenium</Option>
                </OptionGroup>
                <OptionGroup label='Metal'>
                  <Option value='Ferrous'>Ferrous </Option>
                  <Option value='non-ferrous'>Non Ferrous</Option>
                  <Option value='scrap'>Scrap</Option>
                </OptionGroup>
                <OptionGroup label='Prime and Recycled Plastics'>
                  <Option value='PVC'>PVC</Option>
                  <Option value='Polyethylene'>Polyethylene</Option>
                  <Option value='Polystyrene'>Polystyrene</Option>
                  <Option value='Polypropylene'>Polypropylene</Option>
                  <Option value='PET'>PET</Option>
                </OptionGroup>
                {/* <OptionGroup label='Foods'></OptionGroup>
                <OptionGroup label='Auto Industry'></OptionGroup>
                <OptionGroup label='Property'></OptionGroup> */}
              </Select>
            </div>
            <div className='w-full'>
              <TextField
                // id='outlined-textarea'
                label='Email'
                name='user_email'
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
                // placeholder='Placeholder'
                style={{ width: '95%', margin: '2%' }}

                // multiline
              />
            </div>
            <div className='w-full'>
              <TextField
                required
                value={message}
                name='message'
                onChange={(e) => setmessage(e.target.value)}
                id='outlined-multiline-static'
                label='How we can help you?'
                style={{ width: '95%', margin: '2%' }}
                multiline
                rows={4}
              />
            </div>

            <div className='uppercase w-full flex justify-center'>
              <Button
                variant='outline-success'
                type='submit'
                // onClick={() => alert('Submitted')}
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'poppins',
                  borderColor: '#DAA520',
                }}
                className='uppercase'
              >
                Submit
              </Button>
              {/* <input
                variant='outline-primary'
                type='submit'
                value='Send'
                style={{ fontWeight: 'bold', fontFamily: 'poppins' }}
                className='uppercase'
              /> */}
            </div>
            {form_check && (
              <div className='uppercase w-full flex justify-center'>
                <CheckCircleIcon />{' '}
                <p
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'poppins',
                    color: '#139c76',
                  }}
                >
                  Thank your for submitting your query
                </p>
              </div>
            )}
          </form>
        </div>
      </Box>
      <Footer />
    </Box>
  );
}

function Select(props) {
  const slots = {
    root: ButtonStyled,
    listbox: Listbox,
    popper: Popper,
    ...props.slots,
  };

  return <BaseSelect {...props} slots={slots} />;
}

Select.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
};

const OptionGroup = React.forwardRef(function CustomOptionGroup(props, ref) {
  const slots = {
    root: GroupRoot,
    label: GroupHeader,
    list: GroupOptions,
    ...props.slots,
  };

  return <BaseOptionGroup {...props} ref={ref} slots={slots} />;
});

OptionGroup.propTypes = {
  /**
   * The components used for each slot inside the OptionGroup.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    label: PropTypes.elementType,
    list: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const ButtonStyled = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type='button' {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

ButtonStyled.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled('button', { shouldForwardProp: () => true })(
  ({ theme }) => `
  position: relative;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
 width: 95%;
 margin-left:10px;
 padding: 15px;
//   padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const Listbox = styled('ul')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 320px;
  height:300px;
  border-radius: 12px;
  overflow-y: scroll;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
  };
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `
);

const GroupRoot = styled('li')`
  list-style: none;
`;

const GroupHeader = styled('span')`
  display: block;
  padding: 15px 0 5px 10px;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${grey[600]};
`;

const GroupOptions = styled('ul')`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding-left: 20px;
  }
`;

const Popper = styled(BasePopper)`
  z-index: 40;
`;

export default Contact;
